import React from 'react';
import './header.css';
import LinkComponent from './Link-Component';

function Header() {
  return (
    <div className="Header">
      <div className="Header-title">Hi! I am Akshit</div>
      <div>{'I am a software developer. \nI mostly work with '}<LinkComponent
        title="Javascript"
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
      /> and <LinkComponent
        title="React"
        href="https://reactjs.org/"
      />. {'\nI develop cross-platform applications for mobile devices such as '}<LinkComponent 
      title="iPhones" href="https://www.apple.com/in/iphone/" />, <LinkComponent 
      title="iPads" href="https://www.apple.com/in/ipad/" />, and <LinkComponent 
      title="Android" href="https://www.android.com/intl/en_in/" /> devices.</div>
    </div>
  )
}

export default Header;