import React from 'react';
import './Item-Box.css';
import Redirect from '../redirect.png';

function ItemBox(props) {
  return (
    <div className="Item-Box">
      <img src={props.image} alt={props.title}/>
      <div className="Item-footer">
        {props.link ? <a
          href={props.link}
          target="_blank"
          rel="noopener noreferrer">{props.title}</a>
          : props.title}{props.link && <img src={Redirect} className="Item-redirect" alt="redirect"/>}
      </div>
    </div>
  )
}

export default ItemBox;