import React from 'react';
import './repositories.css';
import Redirect from '../redirect_dark.png';
import RepoItem from './Repo-Item';
import IconComponent from './Icon-Component';

function Electronics() {
  return (
    <div className="Repositories">
      <div className="Repositories-title">Some repositories I've made</div>
      <div className="Repositories-grid">
        <div className="Repositories-row">
          <RepoItem title="React-Native-Bubble-Chart" 
          link="https://github.com/akshit5230/React-Native-Bubble-Chart"
          desc="A d3 and svg generated bubble charts in pure javascript"/>
          <RepoItem title="React-Native-Material-TextInput" 
          link="https://github.com/akshit5230/React-Native-Material-TextInput"
          desc="A simple TextInput wrapper for material styling in iOS and Android"/>
        </div>
        <div className="Repositories-row">
          <RepoItem title="react-native-notification-channels" 
          link="https://github.com/akshit5230/react-native-notification-channels"
          desc="A react-native package which contains helper functions for handling notification channels in Android 8.0+"/>
          <RepoItem title="React-Native-Collapsible-Section" 
          link="https://github.com/akshit5230/React-Native-Collapsible-Section"
          desc="A collapsible section / section list with customizable section body and child body. Supports both iOS and Android."/>
        </div>
      </div>
      <div className="Repositories-footer">There's more on my <IconComponent 
      icon={["fab", "github"]} size="1.5x" color="#282c34" 
      href="https://github.com/akshit5230" /><img src={Redirect} className="Repositories-redirect"  alt="redirect"/></div>
    </div>
  )
}

export default Electronics;