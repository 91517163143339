import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './footer.css';
import IconComponent from './Icon-Component';

function Footer() {
  return (
    <div className="Footer">
      <IconComponent icon={["fab", "github"]} size="2x" color="#282c34" 
        href="https://github.com/akshit5230" style={{ marginRight: '20px'}}/>
      <IconComponent icon={["fab", "stack-overflow"]} size="2x" color="#282c34" 
        href="https://stackoverflow.com/users/6025307/akshit-w"
        style={{ marginRight: '20px'}}/>
      <IconComponent icon={["fab", "youtube"]} size="2x" color="#282c34" 
        href="https://youtube.com/akshit5230" style={{ marginRight: '20px'}}/>
      <IconComponent icon={["fab", "steam"]} size="2x" color="#282c34" 
        href="https://steamcommunity.com/id/akki5230"/>
    </div>
  )
}

export default Footer;