import React from 'react';
import './Link-Component.css';

function LinkComponent(props) {
  return (
    <a
      className="Custom-link"
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={props.style}
    >
      {props.title}
    </a>
  )
}

export default LinkComponent;