import React from 'react';
import './waterfly.css';
import SS from '../waterfly_2.png';
import Redirect from '../redirect.png';
import LinkComponent from './Link-Component';

function Waterfly() {
  return (
    <div className="Waterfly">
      <div className="Waterfly-image"><img src={SS} className="Waterfly-ss" alt="waterfly preview"/></div> 
      <div className="Waterfly-data">
        <div className="Waterfly-title">Waterfly</div>
        <div className="Waterfly-subtitle">Motivational Reminders</div>
        <div className="Waterfly-description">{'A simple water reminder app to keep you hydrated! \nIt will remind you every hour to drink water. \nYou can set how many times you need to drink water in a day.'}</div>
        <div className="Waterfly-link">Available on <LinkComponent
          title="Play Store"
          href="https://play.google.com/store/apps/details?id=com.waterfly_sr"
          style={{ color: '#FFFFFF', fontWeight: 600 }}
        /><img src={Redirect} className="Waterfly-redirect" alt="redirect"/></div>
      </div>
    </div>
  )
}

export default Waterfly;