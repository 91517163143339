import React from 'react';
import './App.css';
import Header from './components/header';
import Waterfly from './components/waterfly';
import Electronics from './components/electronics';
import Footer from './components/footer';
import Tutorials from './components/tutorials';
import Repositories from './components/repositories';
import Travel from './components/travel';

function App() {
  return (
    <div className="App">
      <Header />
      <Waterfly />
      <Electronics />
      <Tutorials />
      <Repositories />
      <Travel />
      <Footer />
    </div>
  );
}

export default App;
