import React from 'react';
import LinkComponent from './Link-Component';
import './Repo-Item.css';

function RepoItem(props) {

  return (
    <div className="Repo-Container">
      <LinkComponent
        title={props.title}
        href={props.link}
      />
      <div className="Repo-desc">{props.desc}</div>
    </div>
  )
}

export default RepoItem;