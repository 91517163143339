import React from 'react';
import './Link-Component.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconComponent(props) {
  return (
    <a
      className="Custom-link"
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={props.style}
    >
      <FontAwesomeIcon icon={props.icon} size={props.size} color={props.color} />
    </a>
  )
}

export default IconComponent;