import React from 'react';
import './tutorials.css';
import LinkComponent from './Link-Component';
import tutorialImage from '../tutorials.png'
import Redirect from '../redirect.png';

function Header() {
  return (
    <div className="Tutorial">
      <div className="Tutorial-title">I also teach good coding practices!</div>
      <div className="Tutorial-image"><img src={tutorialImage} className="Tutorial-ss" alt="tutorial preview"/></div> 
      <div className="Tutorial-desc">You can watch my <LinkComponent
          title="React Native Tutorials"
          href="https://www.youtube.com/playlist?list=PLuD-QWsDG-elW9JyCrN3-GWGw979IO0se"
          style={{ color: '#FFFFFF', fontWeight: 600 }}
        /><img src={Redirect} className="Tutorial-redirect" alt="redirect"/> to get started for free.</div>
    </div>
  )
}

export default Header;