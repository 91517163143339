import React from 'react';
import './electronics.css';
import Redirect from '../redirect_dark.png';
import LinkComponent from './Link-Component';
import ItemBox from './Item-Box';
import LedCube4 from '../4_led_cube.jpg';
import DigitalFm from '../digital_fm.JPG';
import GamingPc from '../gaming_pc.JPG';
import MusicCube from '../music_cube.JPG';
import WirelessHp from '../wireless_headphone.jpg';
import LedDice from '../led_dice.JPG';

function Electronics() {
  return (
    <div className="Electronics">
      <div className="Electronics-title">Some electronics stuff I've made</div>
      <div className="Electronics-grid">
        <div className="Electronics-row">
          <ItemBox image={LedCube4} title="4x4x4 LED Cube" link="https://www.instructables.com/id/Simple-LED-Cube-4x-with-Arduino328p/"/>
          <ItemBox image={DigitalFm} title="Digital FM Radio"/>
        </div>
        <div className="Electronics-row">
          <ItemBox image={GamingPc} title="Gaming Build" link="https://www.instructables.com/id/How-to-Build-a-Budget-Gaming-PC-Detailed-Informati/"/>
          <ItemBox image={MusicCube} title="Music Controlled Cube"/>
        </div>
        <div className="Electronics-row">
          <ItemBox image={WirelessHp} title="Wireless Headset Adapter" link="https://www.instructables.com/id/Simple-Turn-Any-Headphone-into-Wireless-Headset/"/>
          <ItemBox image={LedDice} title="LED Dice"/>
        </div>
      </div>
      <div className="Electronics-footer">There's more on my <LinkComponent 
      href="https://www.instructables.com/member/akki5230/"
      title="Instructables page" /><img src={Redirect} className="Electronics-redirect"  alt="redirect"/></div>
    </div>
  )
}

export default Electronics;